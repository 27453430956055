// import PropTypes from 'prop-types'
import React from 'react'
import arrowDown from '../images/arrow-down.png'
import logoBig from '../images/logo-big.png'

const Hero = () => (
  <section className="hero">
    <div className="hero-main-content wrapper">
      <img
        src={logoBig}
        alt=""
        className="hero-image"
        width="324"
        height="385"
      />
      <div className="hero-title">
        <h2>
          <span className="text-white hero-title__first">Edukacja</span>{' '}
          <span className="hero-title__second">
            muzyczna
          </span>
        </h2>
      </div>
    </div>
    <div className="hero-arrow-down">
      Zjedź w dół
      <a href="#scrollTo">
        <img src={arrowDown} alt="arrow-down" />
      </a>
    </div>
    <div
      id="scrollTo"
      style={{
        position: 'absolute',
        bottom: '50px',
      }}
    />
  </section>
)

export default Hero
