import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Carousel from '../components/carousel'

const IndexPage = () => (
  <>
    <SEO
      title="Music Spot -  Nauka Gry na Gitarze i Lekcje Śpiewu  "
      description="Profesjonalna Nauka Gry na Gitarze i Lekcje Śpiewu"
      keywords={[
        `nauka gitary`,
        `nauka śpiewu`,
        `jaworzno`,
        `katowice`,
        `jazz`,
        `szkoła muzyczna`,
      ]}
    />
    <Hero />
    <section className="description wrapper">
      <h2 className="description-title">
        Zapraszamy do świata{' '}
        <span className="text-highlight-yellow">muzyki.</span>
      </h2>
      <p>
        Music Spot to miejsce, gdzie każdy może rozpocząć swoją muzyczną 
        przygodę lub rozwijać swoje umiejętności, niezależnie od wieku czy 
        doświadczenia. Nasza oferta jest skierowana do dzieci, młodzieży i 
        dorosłych, w tym tych, którzy marzą o karierze profesjonalnego muzyka.
        Dołącz do nas i przekonaj się, jak świetnie może brzmieć twoja przygoda 
        z dźwiękami!{' '}
      </p>
    </section>
    <section className="carousel wrapper" style={{ marginBottom: '60px' }}>
      <Carousel />
    </section>


  </>
)

export default IndexPage
