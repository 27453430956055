import React, { Component } from 'react'
import Slider from 'react-slick'

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <Slider {...settings}>
        <div>
          <h3>30 minut lekcja próbna gratis</h3>
        </div>
        <div>
          <h3 className="small">
            nauka na gitarze elektrycznej, klasycznej, akustycznej, basowej
          </h3>
        </div>
        <div>
          <h3>nauka śpiewu oraz lekcje emisji głosu</h3>
        </div>
      </Slider>
    )
  }
}
